.menuStyle{
   
    border-radius: 25px;
    width: 250px;
    background-color: rgba(255, 255, 255, 0.80) ;
   
}
@media only screen and (max-width: 600px) {
	.menuStyle {
		width: auto;
	}
}
.navbar-light .navbar-nav .nav-link{
    color: #5668a8!important;
    font-weight: 700;
}
.navbar-light .navbar-nav .nav-link:hover{
    color: #2cb6bf!important;

}
#btn-menuBlue{
    background-color:#5668a8;
    border-radius: 25px;
    margin: 7px 0px;
    padding: 0px 17px;
    color: white !important;
}
.responsive-menu-logo {
    width: 100%;
    max-width: 215px;
    height: auto;
  }