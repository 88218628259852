:root {
  --color-chambay: #32458f;
  --color-primary: #4267cb;
  --color-secondary: #ebf1fe;
  --color-delta: #a5a5a4;
  --color-white: #ffffff;
  --color-black: #010101;
  --color-concrete: #f3f3f3;
  --color-emperor: #535353;
  --color-silver: #bebebe;
  --color-turquoise: #00faf6;
  --color-cornflower: #99a2c7;
  --color-zodiac: #12264f;
  --color-error: #d8000c;
  --color-bg-error: #ffbaba;
  --color-success: #4f8a10;
  --color-bg-success: #dff2bf;
}

@font-face {
  font-family: "Ford Antenna Cond";
  src: local("Ford Antenna Cond Regular"), local("Ford-Antenna-Cond-Regular"),
    url("./assets/webfonts/FordAntennaCond-Regular/FordAntennaCond-Regular.woff2")
      format("woff2"),
    url("./assets/webfonts/FordAntennaCond-Regular/FordAntennaCond-Regular.woff")
      format("woff"),
    url("./assets/webfonts/FordAntennaCond-Regular/FordAntennaCond-Regular.ttf")
      format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Ford Antenna";
  src: local("Ford Antenna Light"), local("Ford-Antenna-Light"),
    url("./assets/webfonts/FordAntenna-Light/FordAntenna-Light.woff2")
      format("woff2"),
    url("./assets/webfonts/FordAntenna-Light/FordAntenna-Light.woff")
      format("woff"),
    url("./assets/webfonts/FordAntenna-Light/FordAntenna-Light.ttf")
      format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Ford Antenna";
  src: local("Ford Antenna Regular"), local("Ford-Antenna-Regular"),
    url("./assets/webfonts/FordAntenna-Regular/FordAntenna-Regular.woff2")
      format("woff2"),
    url("./assets/webfonts/FordAntenna-Regular/FordAntenna-Regular.woff")
      format("woff"),
    url("./assets/webfonts/FordAntenna-Regular/FordAntenna-Regular.ttf")
      format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Ford Antenna";
  src: local("Ford Antenna Medium"), local("Ford-Antenna-Medium"),
    url("./assets/webfonts/FordAntenna-Medium/FordAntenna-Medium.woff2")
      format("woff2"),
    url("./assets/webfonts/FordAntenna-Medium/FordAntenna-Medium.woff")
      format("woff"),
    url("./assets/webfonts/FordAntenna-Medium/FordAntenna-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Ford Antenna";
  src: local("Ford Antenna Black"), local("Ford-Antenna-Black"),
    url("./assets/webfonts/FordAntenna-Black/FordAntenna-Black.woff2")
      format("woff2"),
    url("./assets/webfonts/FordAntenna-Black/FordAntenna-Black.woff")
      format("woff"),
    url("./assets/webfonts/FordAntenna-Black/FordAntenna-Black.ttf")
      format("truetype");
  font-weight: 900;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.heading2 {
  font-family: "Ford Antenna";
  font-weight: 800;
  margin-top: 5.125rem;
  color: var(--color-black);
  text-align: center;
  margin-bottom: 5.625rem;
}
