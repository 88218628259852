.CFooBg{
    background-image: linear-gradient(90deg, rgba(50, 69, 143, 0.5),rgba(106, 179, 224, 0.5)), url(contactfooter.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
  }
.CFooH{
  height: 250px;
}
  
.footer{
    min-height: 250px;
    text-align: center;
    color:white;
    background-color: #0A0C28;
}
