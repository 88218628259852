@font-face {
    font-family: Poppins_Regular;
    src: url(./assets/fonts/Poppins-Regular.ttf);
}

@font-face {
    font-family: Poppins_Bold;
    src: url(./assets/fonts/Poppins-Black.ttf);
}

body {
    font-family: Poppins_Regular;
}

.carousel-indicators [data-bs-target] {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .55);

}

.menubg {
    background: white;
}

.whatsapp button {
    position: fixed;
    right: 60px;
    bottom: 60px;
    z-index: 2;
    height: 45px;
    width: 45px;
    border-radius: 30px;
    border: none;
    background-color: #25d366;
}

.whatsapp button svg {
    color: white;
}

.HelloWhatsapp {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    text-align: center;
    right: 125px;
    bottom: 100px;
    position: fixed;
    width: 170px;
    height: auto;
    padding: 10px;
    background-color: white;
    opacity: 0;
    transition: 0.5s all;

}

.HelloCenter {

    color: white;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

.HelloWhatsapp, .circulo1, .circulo2 {
    position: fixed;
    opacity: 0;
    transition: 0.5s all;
    border-radius: 25px;
    background-color: #25d366;
    z-index: -1;
    border: solid 2px white; /*bordes de nube*/

}

.circulo1 {
    right: 97px;
    bottom: 102px;
    height: 10px;
    width: 10px;
}

.circulo2 {
    right: 106px;
    bottom: 112px;
    height: 14px;
    width: 14px;
}

.whatsapp:hover .HelloWhatsapp, .whatsapp:hover .circulo1, .whatsapp:hover .circulo2 {
    opacity: 1;
    z-index: 1;
}

.btn-line {
    color: #fff;
    background-color: #008CD3;
    border-color: #008CD3;
    border-radius: 25px;
}

.btn:hover {
    background-color: #008CD3;
    color: white;
    border-color: #008CD3;
}

.btn-pack {
    color: #fff;
    background-color: #008CD3;
    border-color: #008CD3;
    border-radius: 25px;
    font-size: 14px;
    padding: 10px 12px;
}

/*About Styles*/
.TituloAbout {
    font-weight: 500;
}

.textAbout {
    font-size: 1.2rem;
}

.AboutBox {
    height: 200px;
    color: white;
    text-align: center;

    background: linear-gradient(90deg, #008CD3, #2D2B80);
}

.AboutBox h1 {
    font-weight: 200;
}

.AboutBox h1 span {
    font-weight: 600;
}

.Paquete {
    border: solid 2px #3C64B1;
}

.TipoPrecio {
    font-size: 10px;
    line-height: 0.8;
}

.Incluido, .Detalles {
    font-size: 10px;;
}

/*Blog Style*/
.BlogBg {
    background-image: linear-gradient(90deg, rgba(50, 69, 143, 0.5),
    rgba(106, 179, 224, 0.5)), url(./images/blog.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
}

.post {
    position: relative;
    font-size: 12px;
    border: solid 3px #3C64B1;
    padding: 20px;
    min-height: 330px;
}

.botonPost {
    position: absolute;
    bottom: 25px;
    right: 25px;

}

.postInText {
    border: solid 2px #3C64B1;
    padding: 35px;
}

/*Contacto Style*/
.title-contact {
    margin-top: 60px;
    color: white;
    font-size: 48px;
}

.text-contact {
    margin-top: 25px;
    color: white;
    font-size: 18px;
}

.info-contact {
    color: white;
    font-size: 1rem;
}

.form-control {
    border-radius: 30px;
}

.form-floating > .form-control, .form-floating > .form-select {
    height: 40px;
    padding-left: 21px;
}

.form-floating > label {
    padding: 7px 14px;
    color: #008CD3;
    font-weight: 600;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
    transform: scale(.85) translateY(-7px) translateX(10px);
}
.focus {
    transform: scale(.85) translateY(-7px) translateX(10px);
    opacity: .65;
}

.form-focus {
    padding-top: 1.625rem;
    padding-bottom: .625rem;
    padding-left: 21px;
    height: 40px;

}

.text-area {
    min-height: 160px !important;
}

.bg-contact {
    min-height: 890px;
    width: 100%;
    background-image: linear-gradient(90deg, rgba(106, 179, 224, 0.6), rgba(50, 69, 143, 0.6)
    ), url(./images/contact.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

/*switch Boton Paquetes*/
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switchBox {
    line-height: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2196F3; /*#2196F3*/
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #00B1B2;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em;
    color: #000;
    background: transparent url("/src/equis.svg") 50%/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5;
}

button.close > span {
    display: none;
}

.mediaLuna {

    width: 100%;
    height: 750px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(./images/medialuna.png);
    color: white;

}

.terms-conditions {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 20px;
}

.terms-conditions h1 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 15px;
    text-align: center;
}

.centro {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
}

/* Estilo del scrollbar en general */
textarea::-webkit-scrollbar {
    width: 12px; /* Ancho del scrollbar */
    background-color: #f5f5f5; /* Color de fondo del scrollbar */
}

/* Estilo de la barra de desplazamiento (thumb) */
textarea::-webkit-scrollbar-thumb {
    background-color: #888; /* Color de la barra de desplazamiento */
    border-radius: 6px; /* Bordes redondeados */
    border: 2px solid #f5f5f5; /* Borde y color del borde */
}

/* Estilo de la barra de desplazamiento (thumb) al pasar el mouse */
textarea::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color de la barra al pasar el mouse */
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}